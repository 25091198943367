import React, { Suspense } from 'react'
import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
import Admin from "./component/admin/Admin";
import SignIn from "./component/admin/SignIn";
import ProtectedRoute from "./auth/protected-reoute";
import {useAuth} from "./auth/useAuth";
import NewRequest from "./component/NewRequest";
import ThankYou from "./component/ThankYou";
import './i18n'
import Application from "./component/Application";

function App() {
    const {isLoading, user} = useAuth();
    return (
        <Suspense fallback={null}>
            <Router>
                <Switch>
                    <Route path="/sign-in">
                        <SignIn/>
                    </Route>
                    <ProtectedRoute isAuthed={!!user} isLoading={isLoading} path="/admin">
                        <Admin/>
                    </ProtectedRoute>
                    <Route path="/thanks">
                        <ThankYou/>
                    </Route>
                    <Route path="/request">
                        <NewRequest/>
                    </Route>
                    <Route path="/">
                        <Application/>
                    </Route>
                </Switch>
            </Router>
        </Suspense>
    );
}

export default App;
