import React from "react";
import {useTranslation} from "react-i18next";
import InputText from "../../ui/InputText";
import Select from "../../ui/Select";
import {
    getBuildingType,
    getFloors,
    getLifts,
    getMovingFlexibleDate,
    getMovingOtherTask,
    getRooms
} from "../../../utils/constants";
import SelectMultiOption from "../../ui/SelectMultiOption";

const AddressInfo = ({formikProps, isMoving}) => {
    const {t} = useTranslation()

    return (
        <div>
            <h3>{isMoving ? t('from.label') : t('address.label')}</h3>
            <div className="grid grid--stepper gap-2">
                <InputText
                    id="zip"
                    label={t('zip.label')}
                    placeholder={t('zip.label')}
                    value={formikProps.values.zip}
                    error={formikProps.errors.zip}
                    touched={formikProps.touched.zip}
                    blurHandler={formikProps.handleBlur}
                    type="text"
                    onChangeHandler={formikProps.handleChange}
                />
                <InputText
                    id="location"
                    label={t('location.label')}
                    placeholder={t('location.label')}
                    value={formikProps.values.location}
                    error={formikProps.errors.location}
                    touched={formikProps.touched.location}
                    blurHandler={formikProps.handleBlur}
                    type="text"
                    onChangeHandler={formikProps.handleChange}
                />
                <InputText
                    id="street"
                    label={t('street.label')}
                    placeholder={t('street.label')}
                    value={formikProps.values.street}
                    error={formikProps.errors.street}
                    touched={formikProps.touched.street}
                    blurHandler={formikProps.handleBlur}
                    type="text"
                    onChangeHandler={formikProps.handleChange}
                />
                <InputText
                    id="no"
                    label={t('no.label')}
                    placeholder={t('no.label')}
                    value={formikProps.values.no}
                    error={formikProps.errors.no}
                    touched={formikProps.touched.no}
                    blurHandler={formikProps.handleBlur}
                    type="text"
                    onChangeHandler={formikProps.handleChange}
                />
                <InputText
                    id="movingDate"
                    label={t('date.label')}
                    placeholder={t('date.label')}
                    value={formikProps.values.movingDate}
                    error={formikProps.errors.movingDate}
                    touched={formikProps.touched.movingDate}
                    blurHandler={formikProps.handleBlur}
                    type="date"
                    onChangeHandler={formikProps.handleChange}
                />
                <Select
                    id="movingFlexibleDate"
                    label={t('date.flexible.label')}
                    params={getMovingFlexibleDate(t)}
                    value={formikProps.values.movingFlexibleDate}
                    touched={formikProps.touched.movingFlexibleDate}
                    error={formikProps.errors.movingFlexibleDate}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <Select
                    id="buildingType"
                    label={t('building.type.label')}
                    params={getBuildingType(t)}
                    value={formikProps.values.buildingType}
                    touched={formikProps.touched.buildingType}
                    error={formikProps.errors.buildingType}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <Select
                    id="lift"
                    label={t('lift.label')}
                    params={getLifts(t)}
                    value={formikProps.values.lift}
                    touched={formikProps.touched.lift}
                    error={formikProps.errors.lift}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <Select
                    id="floor"
                    label={t('floor.label')}
                    params={getFloors(t)}
                    value={formikProps.values.floor}
                    touched={formikProps.touched.floor}
                    error={formikProps.errors.floor}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <Select
                    id="room"
                    label={t('room.count.label')}
                    params={getRooms(t)}
                    value={formikProps.values.room}
                    touched={formikProps.touched.room}
                    error={formikProps.errors.room}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <InputText
                    id="area"
                    label={t('area.label')}
                    placeholder={t('area.label')}
                    value={formikProps.values.area}
                    error={formikProps.errors.area}
                    touched={formikProps.touched.area}
                    blurHandler={formikProps.handleBlur}
                    type="text"
                    onChangeHandler={formikProps.handleChange}
                />
            </div>
            {isMoving && <SelectMultiOption prefix="moving" value={formikProps.values.movingOptions}
                                            changeHandler={formikProps.handleChange} options={getMovingOtherTask(t)}/>}
        </div>
    )
}

export default AddressInfo;