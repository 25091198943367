import * as Yup from 'yup';

const addressInfoValidation = (requiredText, numberCheck) => {
    return {
        zip: Yup.string().required(requiredText),
        location: Yup.string().required(requiredText),
        street: Yup.string().required(requiredText),
        no: Yup.number().typeError(numberCheck).required(requiredText),
        lift: Yup.string().required(requiredText),
        floor: Yup.string().required(requiredText),
        room: Yup.string().required(requiredText),
        area: Yup.number().typeError(numberCheck).required(requiredText),
        movingDate: Yup.date().required(requiredText),
        movingFlexibleDate: Yup.string().required(requiredText),
        buildingType: Yup.string().required(requiredText),
    }
}

const movingServiceInfoValidation = (requiredText, numberCheck) => {
    return {
        to_zip: Yup.string().required(requiredText),
        to_location: Yup.string().required(requiredText),
        to_street: Yup.string().required(requiredText),
        to_no: Yup.number().typeError(numberCheck).required(requiredText),
        to_lift: Yup.string().required(requiredText),
        to_floor: Yup.string().required(requiredText),
        to_buildingType: Yup.string().required(requiredText),
    }
}

const cleaningServiceInfoValidation = (requiredText, numberCheck) => {
    return {
        cleaning_windows:Yup.string().required(requiredText),
        cleaning_shower:Yup.number().typeError(numberCheck).required(requiredText),
        cleaning_bath:Yup.number().typeError(numberCheck).required(requiredText),
        cleaning_wc:Yup.number().typeError(numberCheck).required(requiredText),
        cleaning_type_floor:Yup.number().typeError(numberCheck).required(requiredText),
        cleaning_window_sizes:Yup.number().typeError(numberCheck).required(requiredText),
    }
}

const contactInfoValidation = (requiredText, numberCheck, emailValidationErr) => {
    return {
        contact_title: Yup.string().required(requiredText),
        fullName: Yup.string().required(requiredText),
        phone: Yup.number().typeError(numberCheck).required(requiredText),
        contact_availability: Yup.string().required(requiredText),
        email: Yup.string().email(emailValidationErr).required(requiredText),
    }
}

const getFormValidation = (planInfo, translate) => {
    const requiredText = translate("required.validation");
    const numberCheck = translate("number.type.validation");
    const emailValidationErr = translate("email.validation");

    const schema = {
        ...addressInfoValidation(requiredText, numberCheck),
        ...contactInfoValidation(requiredText, numberCheck, emailValidationErr)
    };

    if (planInfo.isMoving) {
        Object.assign(schema, movingServiceInfoValidation(requiredText, numberCheck))
    }

    if (planInfo.isCleaning) {
        Object.assign(schema, cleaningServiceInfoValidation(requiredText, numberCheck))
    }

    return Yup.object({
        ...schema
    })

}

export default getFormValidation;