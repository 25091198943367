const addressInfo = {
    zip: "",
    location: "",
    street: "",
    no: null,
    lift: "",
    floor: "",
    room: "",
    area: null,
    movingDate: null,
    movingFlexibleDate: "",
    buildingType: "",
}

const destinationInfo = {
    to_zip: "",
    to_location: "",
    to_street: "",
    to_no: null,
    to_buildingType: "",
    to_lift: "",
    to_floor: "",
}

const cleaningServiceInfo = {
    cleaning_windows: "",
    cleaning_shower: null,
    cleaning_bath: null,
    cleaning_wc: null,
    cleaning_type_floor: null,
    cleaning_window_sizes: null,
}

const contactInfo = {
    contact_title: "",
    fullName: "",
    phone: "",
    contact_availability: "",
    email: "",
}

const getFormInitialValue = () => {
    return {
        ...addressInfo,
        ...contactInfo,
        ...destinationInfo,
        ...cleaningServiceInfo,
        comments: "",
    };
}

export default getFormInitialValue;