import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth'
import 'firebase/firestore'
import './assets/css/normilize.css';
import './assets/css/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import store from './store/configureStore'

const firebaseConfig = {
    apiKey: "AIzaSyDWRRHKLRl0uyRbK4xJ54mfbSPfzmpNyGA",
    authDomain: "safari-moving.firebaseapp.com",
    projectId: "safari-moving",
    storageBucket: "safari-moving.appspot.com",
    messagingSenderId: "133159148997",
    appId: "1:133159148997:web:50415206e07bbfe981bfac",
    measurementId: "G-VMSNWQTV71"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();


ReactDOM.render(
  <React.StrictMode>
      <Provider store={store}>
          <App />
      </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
