import firebase from "firebase/app";

const collectionName = "requests"

export const persist = async (request) => {
    await firebase.firestore().collection(collectionName).doc().set(request)
}

export const fetchAllNotDeleted = async () => {
    return (await firebase.firestore()
        .collection(collectionName)
        .where("deleted", "==", false)
        .get()).docs.map(doc => ({
        id: doc.id,
        ...doc.data()
    }))
}

export const deleteRequest = async id => {
    await firebase.firestore().collection(collectionName).doc(id).update({deleted: true})
}