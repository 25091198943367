import {createSlice} from "@reduxjs/toolkit";

const stepSlice = createSlice({
    name: "step",
    initialState: {
        step: 0,
    },
    reducers: {
        incrementStep: (state) => {
            if (state.step < 3) {
                state.step += 1;
            }
        },
        decrementStep: (state) => {
            if (state.step > 0) {
                state.step -= 1;
            }
        },
        stepReset: (state) => {
            state.step = 0
        }
    }
})

export const {incrementStep, decrementStep, stepReset} = stepSlice.actions;
export default stepSlice.reducer