const InputText = ({label, placeholder, type, id, touched, error, blurHandler, value, onChangeHandler}) => {
    const isRequired = touched && error

    const getLabel = () => {
        if (isRequired) {
            return "input__label input__label-required"
        }
        return "input__label"
    }

    return (
        <div>
            <label className={getLabel()} htmlFor={id}>{label}</label>
            <input className={isRequired ? "normal-input normal-input-required":"normal-input" } id={id} name={id} type={type} value={value} onChange={onChangeHandler} placeholder={placeholder} onBlur={blurHandler}/>
            {isRequired ? <span className="input__label-required">{error}</span> : null}
        </div>
    )
}

export default InputText