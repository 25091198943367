import Navbar from "./Navbar";
import BlockHero from "./BlockHero";
import BlockFeature from "./BlockFeature";
import BlockShadow from "./BlockShadow";
import BlockTestimonial from "./BlockTestimonial";
import BlockFooter from "./BlockFooter";

const Application = () => {
    return (
        <>
            <Navbar/>
            <BlockHero/>
            <BlockFeature/>
            <BlockShadow/>
            {/*<BlockTestimonial/>*/}
            <BlockFooter/>
        </>
    )
}

export default Application;