import React from "react";
import {useTranslation} from "react-i18next";

const StepperBtn = ({handlerNext, handleBack, step}) => {
    const { t } = useTranslation()

    return (
        <div className="center--content">
            {
                step > 0 ? <button className="btn btn--secondary btn-gap" onClick={handleBack}>{t('back.btn')}</button> : null
            }
            {
                <button className="btn btn--primary btn-gap"
                        onClick={handlerNext}>{step < 1 ? t('next.btn') : t('send.offer.request.btn')}</button>
            }
        </div>
    )
}

export default StepperBtn;