import {useEffect, useState} from "react";
import {getCurrentUser} from "./get-current-user"
import {addAuthOnchangeHandler} from "./auth-change-listener"

export const useAuth = () => {
    const [authInfo, setAuthInfo] = useState(() => {
        const user = getCurrentUser();
        const isLoading = !user;
        return { isLoading, user };
    });

    useEffect(() => {
        return addAuthOnchangeHandler(user => {
            setAuthInfo({isLoading: false, user})
        });
    }, []);

    return authInfo;
}