import umzungImage from '../assets/images/moving.jpg'
import reinigungImage from '../assets/images/cleaning.jpg'
import entsorgungImage from '../assets/images/disposal.jpg'
import lagerungImage from '../assets/images/storage.jpg'
import Feature from "./Feature";

const BlockFeature = () => {
    return (
        <section className="block container">
            <header className="block__header" data-aos="zoom-in-up" data-aos-duration="500">
                <h2 >Unsere Leistungen</h2>
                <p>
                    {/*Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed, voluptatem.*/}
                </p>
            </header>
            <Feature animationLeft="fade-down-right" animationRight="fade-up-left" title="Umzug" icon="worker" image={umzungImage} description="Wir transportieren Ihr Inventar sicher von A nach B. Sie wählen, ob wir Ihre Sachen packen,
                        demontieren, montieren oder nur transportieren. Wir bieten Lieferwagen mit professionellen
                        und erfahrenen Fahrern und unsere ausgebildeten Zügelmänner sorgen dafür, dass Sie sich entspannt zurücklehnen können."/>
            <Feature animationLeft="fade-down-left" animationRight="fade-up-right" title="Reinigung" icon="broom" image={reinigungImage} description="Unsere ausgebildeten und erfahrenen Reinigungskräfte reinigen Ihre Wohnung blitzblank sauber, so, dass, sie wie renoviert wirkt. Jede Ecke, jede Kante wird mit dem passenden Reinigungsgerät, Reinigungsmittel und Abwasch gereinigt. Auch können Sie wählen, ob Sie ein ökologisches Reinigungsmittel verwenden möchten. Wir versichern Ihnen, dass wir Ihre Wohnung jeweils nach dem Wohnungsabnahmestandard reinigen. In Ihrer Anwesenheit geben wir die Wohnung ab."/>
            <Feature animationLeft="fade-down-right" animationRight="fade-up-left" title="Entsorgung & Räumungen" icon="disposal" image={entsorgungImage} description="Unsere erfahrenen Mitarbeiter räumen Ihr Inventar und bringen es mit dem Lieferwagen und erfahrenen Fahrern zu einem Entsorgungshof, ins Brockenhaus oder zu Auktionen."/>
            <Feature animationLeft="fade-down-left" animationRight="fade-up-right" title="Lagerung" icon="warehouse" image={lagerungImage} description="Unsere Lagerräume umfassen 1m2 bis 40m2. Sie wählen, wie lange Sie Ihre Sachen lagern möchten."/>
        </section>

    )
}

export default BlockFeature