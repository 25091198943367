import {createSlice} from "@reduxjs/toolkit";

const slice = createSlice({
    name: "request",
    initialState: {
        deleted: false,
        plans: [],
        steps: {},
        step1: {},
        step2: {},
    },
    reducers: {
        planAdded: (request, action) => {
            const index = request.plans.findIndex(p => p === action.payload)
            if (index === -1) {
                request.plans = [
                    ...request.plans,
                    action.payload
                ]
            }
        },
        planRemoved: (request, action) => {
            const newArray = request.plans.filter(p => p !== action.payload)
            request.plans = [
                ...newArray
            ]
        },
        step1Added: (request, action) => {
            request.step1 = {
                ...action.payload
            }
        },
        step2Added: (request, action) => {
            request.step2 = {
                ...action.payload
            }
        },
        stepsAdded: (request, action) => {
            request.steps = {
                ...action.payload
            }
        },
        requestCleaned: (request) => {
            request.plans = []
            request.steps = {}
            request.step1 = {}
            request.step2 = {}
        }
    }
})

export const {planAdded, planRemoved, step1Added, step2Added, stepsAdded, requestCleaned} = slice.actions;
export default slice.reducer