import React, {useState} from "react";
import BlockPlan from "./BlockPlan";
import {useTranslation} from "react-i18next";
import Master from "./Master";

const Stepper = () => {
    const {t} = useTranslation()
    const [stepNum, setStepNum] = useState(0);
    const [plans, setPlans] = useState([])

    const stepperNavigationHandler = numb => {
        setStepNum(numb)
    }

    const addPlanInfo = planInfo => {
        setPlans([
            ...planInfo
        ])
    }


    const stepHandler = () => {
        switch (stepNum) {
            case 0:
                return <BlockPlan stepperNavigationHandler={stepperNavigationHandler} plans={plans} updatePlans={addPlanInfo}/>
            case 1:
                return <Master stepperNavigationHandler={stepperNavigationHandler} plans={plans}/>
            default:
                return <div/>
        }
    }

    return (
        <>
            <div className="step-container">
                <ul className="progressbar">
                    <li className={stepNum >= 1 ? "active" : null}>{t('plans.label')}</li>
                    <li>{t('service.details.label')}</li>
                </ul>
            </div>
            <div className="step-content step-height">
                {stepHandler(stepNum)}
            </div>
        </>
    )
}

export default Stepper