import {signOut} from "../../auth/sign-out";
import {useHistory} from "react-router-dom";

const Admin = () => {
    const history = useHistory();

    const signOutHandler = async () => {
            try {
                await signOut();
                history.push("/");
            } catch (e) {
                alert(e.message)
            }
    }

    return (
        <>
            <div>Admin Panel</div>
            <input onClick={signOutHandler} type="button" name="log" id="log" value="Sign Out"/>
        </>
    )
}

export default Admin;