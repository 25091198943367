import sprite from '../assets/images/sprite.svg'
import logo from '../assets/images/logo-Safari-Umzug.png'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const Navbar = () => {
    const [expanded, setExpanded] = useState(false)
    const [navClass, setNavClass] = useState("nav collapsible")
    const { i18n,t } = useTranslation()

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
    }


    const handleExpanded = () => {
        const expandedState = !expanded
        setExpanded(expandedState)

        if (expandedState) {
            setNavClass("nav collapsible collapsible--expanded")
        } else {
            setNavClass("nav collapsible")
        }
    }

    return (
        <nav className={navClass}>
            <div></div>

            <svg onClick={handleExpanded} className="icon icon--white nav__toggler">
                <use href={`${sprite}#menu`}/>
            </svg>

            <ul className="list nav__list collapsible__content">
                <select className="lang-selector" data-width="fit" onChange={changeLanguage}>
                    <option className="lang-selector--option" value="ch">Dutch</option>
                    <option className="lang-selector--option" value="en">English</option>
                </select>
                <li className="nav__item"><a type="application/octet-stream" download="yourpdf.pdf" href="https://firebasestorage.googleapis.com/v0/b/safari-moving.appspot.com/o/rules%2FAllgemeine%20Gesch%C3%A4ftsbedingungen%20MOVE%20ON%20Wyttenbach.pdf?alt=media&token=7cb23e9c-6ef3-4ce9-865d-86439c0571a9">{t('privacy.rules')}</a></li>
            </ul>
        </nav>
    )
}

export default Navbar