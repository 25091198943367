import Stepper from "./send_request/Stepper";
import Navbar from "./Navbar";

const NewRequest = () => {
    return (
       <>
           <Navbar/>
           <div className="block new-request">
               <Stepper/>
           </div>
       </>
    )
}

export default NewRequest