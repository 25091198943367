import sprite from "../assets/images/sprite.svg";

const Media = ({title, icon, description}) => {
    return (
        <div className="media">
            <div className="media__image">
                <svg className="icon icon--primary">
                    <use href={`${sprite}#${icon}`}/>
                </svg>
            </div>
            <div className="media__body">
                <h3 className="media__title">{title}</h3>
                <p>
                    {description}
                </p>
            </div>
        </div>
    )
}

export default Media;