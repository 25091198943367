import overallWork from '../assets/images/overal-work.jpg'
import Media from "./Media";

const BlockShadow = () => {
    return (
        <section className="block block--dark block--skewed-right block-showcase">
            <header className="block__header"  data-aos="zoom-in" >
                <h2>SO FUNKTIONIERT’S</h2>
            </header>
            <div className="container grid grid--1x2 shadow-gap" >
                <img className="" src={overallWork} alt="" data-aos="zoom-in-up" data-aos-duration="1000"/>
                <ul className="list" data-aos="fade-left-up" data-aos-duration="1000">
                    <li>
                        <Media title="" icon="book" description="Buchen Sie unseren Service: online, telefonisch oder per Mail. Wir machen einen unverbindlichen Besichtigungstermin mit Auftragsvertrag ab." />
                    </li>
                    <li>
                        <Media title="" icon="broom" description="Wir reinigen die vereinbarte Fläche und geben die Wohnug mit Garantie ab." />
                    </li>
                    <li>
                        <Media title="" icon="truck" description="Wir packen und transportieren Ihr Inventar von Ihrem alten zu Ihrem neuen Standort. Ausserdem bieten wir Entsorgungen und Räumungen an. Wir halten uns dabei an höchste Sicherheits- und Qualitätsstandarts." />
                    </li>
                    <li>
                        <Media title="" icon="guarantee" description="Wir garanatieren eine professionelle Korrespondenz, Durchführung des gewünschten Auftrages auf höchstem Niveau und für ein faires Preis- und Leistungsverhältnis." />
                    </li>
                </ul>
            </div>
        </section>

    )
}

export default BlockShadow