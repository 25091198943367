import React from 'react';
import {useTranslation} from "react-i18next";

const Select = ({id, label, params, blurHandler, value, onChangeHandler, touched, error}) => {
    const isRequired = touched && error
    const {t} = useTranslation()

    return (
        <div>
            <label className="input__label" htmlFor={id}>{label}</label>
            <select className={isRequired ? "normal-input normal-input-required":"normal-input" } id={id} name={id} onBlur={blurHandler} onChange={onChangeHandler} value={value}>
                <option key={`${id}-option-empty`} value="">{t('select.one.label')}</option>
                {
                    params.map(param => <option key={`${id}-option-${param}`} value={param}>{param}</option>)
                }
            </select>
            {isRequired ? <div className="input__label-required">{error}</div> : null}
        </div>
    )
}

export default Select