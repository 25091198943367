import React from "react";
import Navbar from "./Navbar";
import {useHistory} from "react-router-dom";
import { useTranslation } from 'react-i18next'

const ThankYou = () => {
    const { t } = useTranslation()
    const history = useHistory()

    const clickHandler = () => {
        history.push("/")
    }

    return(
        <>
            <Navbar/>
            <div className="tContainer">
                <h1>{t('tanks.label')}</h1>
                <button className="btn btn--primary btn--stretched" onClick={clickHandler}>{t('home.btn')}</button>
            </div>
        </>
    )
}

export default ThankYou;