import React, {useState} from "react";
import movingOfferLogo from '../../assets/images/moving-logo.png'
import disposalLogo from '../../assets/images/disposal-logo.png'
import cleaningLogo from '../../assets/images/cleaning-logo.png'
import StepperBtn from "./StepperBtn";
import {useTranslation} from "react-i18next";


export const MOVING = "MOVING"
export const CLEANING = "CLEANING"
export const DISPOSAL = "DISPOSAL"

const BlockPlan = ({stepperNavigationHandler, plans, updatePlans}) => {
    const { t } = useTranslation()
    const [iPlans, setIPlans] = useState(plans)
    const btn1 = (iPlans.findIndex(b => b === MOVING) > -1)
    const btn2 = (iPlans.findIndex(b => b === CLEANING) > -1)
    const btn3 = (iPlans.findIndex(b => b === DISPOSAL) > -1)

    const nextStepHandler = () => {
        updatePlans(iPlans)
        stepperNavigationHandler(1)
    }

    const selectHandler = (btn, plan) => {
        if (btn) {
            const newArray = iPlans.filter(p => p !== plan)
            setIPlans([
                ...newArray
            ])
        } else {
            const index = iPlans.findIndex(p => p === plan)
            if (index === -1) {
                const p = [
                    ...iPlans,
                    plan
                ]
                setIPlans(p)
            }
        }
    }

    return (
        <>
            <section className="block block-plans">
                <div className="grid grid--1x3">
                    <div className="plan">
                        <div className="card card--primary">
                            <header className="card__header flex--header">
                                <img className="logo-plan" src={movingOfferLogo}  alt="movingOfferLogo"/>
                                <h3 className="plan__name">{t('moving.offer.label')}</h3>
                            </header>
                            <div className="card__body">
                                <ul className="list list--tick">
                                    <li className="list__item">Unlimited Websites</li>
                                    <li className="list__item">Unlimited Bandwidth</li>
                                    <li className="list__item">100 GB SSD Storage</li>
                                    <li className="list__item">3 GB RAM</li>
                                </ul>
                                <button className={btn1 ? "btn btn--selected btn--block" : "btn btn--outline btn--block"}
                                        onClick={() => selectHandler(btn1, MOVING)}>{btn1 ? t('selected.btn') : t('select.btn')}</button>
                            </div>
                        </div>
                    </div>
                    <div className="plan">
                        <div className="card card--primary">
                            <header className="card__header flex--header">
                                <img className="logo-plan" src={cleaningLogo}  alt="cleaningLogo"/>
                                <h3 className="plan__name">{t('cleaning.offer.label')}</h3>
                            </header>
                            <div className="card__body">
                                <ul className="list list--tick">
                                    <li className="list__item">Unlimited Websites</li>
                                    <li className="list__item">Unlimited Bandwidth</li>
                                    <li className="list__item">100 GB SSD Storage</li>
                                    <li className="list__item">3 GB RAM</li>
                                </ul>
                                <button className={btn2 ? "btn btn--selected btn--block" : "btn btn--outline btn--block"}
                                        onClick={() => selectHandler(btn2, CLEANING)}>{btn2 ? t('selected.btn') : t('select.btn')}</button>
                            </div>
                        </div>
                    </div>
                    <div className="plan ">
                        <div className="card card--primary">
                            <header className="card__header flex--header">
                                <img className="logo-plan" src={disposalLogo}  alt="disposalLogo"/>
                                <h3 className="plan__name">{t('disposal.offer.label')}</h3>
                            </header>
                            <div className="card__body">
                                <ul className="list list--tick">
                                    <li className="list__item">Unlimited Websites</li>
                                    <li className="list__item">Unlimited Bandwidth</li>
                                    <li className="list__item">100 GB SSD Sotrage</li>
                                    <li className="list__item">3 GB RAM</li>
                                </ul>
                                <button className={btn3 ? "btn btn--selected btn--block" : "btn btn--outline btn--block"}
                                        onClick={() => selectHandler(btn3, DISPOSAL)}>{btn3 ? t('selected.btn') : t('select.btn')}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {iPlans.length > 0 ? <StepperBtn step={0} handlerNext={nextStepHandler}/> : null}
        </>
    )
}

export default BlockPlan