import sprite from "../assets/images/sprite.svg";
import {useTranslation} from "react-i18next";

const Feature = ({title, icon, image, description, animationRight, animationLeft}) => {
    const { t } = useTranslation()
    return (
        <article className="grid grid--1x2 feature">
            <div className="feature__content" data-aos={animationLeft} data-aos-duration="1000">
                  <span className="icon-container">
                    <svg className="icon icon--primary">
                      <use href={`${sprite}#${icon}`}/>
                    </svg>
                  </span>
                <h3 className="feature__heading">{title}</h3>
                <p>
                    {description}
                </p>
                <a href="#" className="link-arrow">{t('read.more')}</a>
            </div>
            <img className="feature__image" src={image} alt="" data-aos={animationRight} data-aos-duration="1000" />
        </article>
    )
}

export default Feature