import banner from "../assets/images/banner.png"
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const BlockHero = () => {
    const history = useHistory()
    const { t } = useTranslation()

    const sendTaskHandler = () => {
          history.push("/request")
    }

    return (
        <section className="block block--blue block--skewed-left hero">
            <div className="container grid grid--1x2">
                <header className="block__header hero__content" data-aos="fade-right" data-aos-duration="1000">
                    <h1 className="block__heading">Gäbigumzug Safari</h1>
                    {/*<p className="hero__tagline">Deploy your websites in less than 60 seconds.</p>*/}
                    <a onClick={sendTaskHandler} className="btn btn--accent btn--stretched">{t('get.start.btn')}</a>
                </header>
                <picture data-aos="zoom-in-up" data-aos-duration="1000">
                    <img className="hero__image" src={banner} alt=""/>
                </picture>
            </div>
        </section>
    )
}

export default BlockHero