import React from "react";
import Select from "../../ui/Select";
import {getCleaningOtherTask, getWindowsCount, wc} from "../../../utils/constants";
import {useTranslation} from "react-i18next";
import SelectMultiOption from "../../ui/SelectMultiOption";

const Cleaning = ({formikProps}) => {
    const {t} = useTranslation()

    return (
        <div>
            <h3>{t('cleaning.options.label')}</h3>
            <div className="grid grid--stepper gap-2">
                <Select
                    id="cleaning_windows"
                    label={t('windows.label')}
                    params={getWindowsCount(t)}
                    value={formikProps.values.cleaning_windows}
                    touched={formikProps.touched.cleaning_windows}
                    error={formikProps.errors.cleaning_windows}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <Select
                    id="cleaning_shower"
                    label={t('shower.wc.label')}
                    params={wc}
                    value={formikProps.values.cleaning_shower}
                    touched={formikProps.touched.cleaning_shower}
                    error={formikProps.errors.cleaning_shower}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <Select
                    id="cleaning_bath"
                    label={t('bath.wc.label')}
                    params={wc}
                    value={formikProps.values.cleaning_bath}
                    touched={formikProps.touched.cleaning_bath}
                    error={formikProps.errors.cleaning_bath}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <Select
                    id="cleaning_wc"
                    label={t('wc.label')}
                    params={wc}
                    value={formikProps.values.cleaning_wc}
                    touched={formikProps.touched.cleaning_wc}
                    error={formikProps.errors.cleaning_wc}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <Select
                    id="cleaning_type_floor"
                    label={`${t('type.floor.label')} ( ${t('on.average.label')} )`}
                    params={wc}
                    value={formikProps.values.cleaning_type_floor}
                    touched={formikProps.touched.cleaning_type_floor}
                    error={formikProps.errors.cleaning_type_floor}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <Select
                    id="cleaning_window_sizes"
                    label={`${t('window.sizes.label')} ( ${t('on.average.label')} )`}
                    params={wc}
                    value={formikProps.values.cleaning_window_sizes}
                    touched={formikProps.touched.cleaning_window_sizes}
                    error={formikProps.errors.cleaning_window_sizes}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
            </div>
            <SelectMultiOption prefix="cleaning" value={formikProps.values.cleaningOptions} changeHandler={formikProps.handleChange} options={getCleaningOtherTask(t)}/>
        </div>
    )
}

export default Cleaning;