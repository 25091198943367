import {useState} from "react";
import {signIn} from "../../auth/sign-in";
import {useHistory} from "react-router-dom";

const SignIn = () => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const history = useHistory();

    const handleUsernameInput = event => {
        setUsername(event.target.value);
    }

    const handlePasswordInput = event => {
        setPassword(event.target.value);
    }

    const signInHandler = async () => {
        if (!username || !password) {
            alert("username and password are required")
        } else {
           try {
               await signIn(username, password);
               history.push("/admin");
           } catch (e) {
               alert(e.message)
           }
        }
    }

    return (
        <>
            <h2>Login Page</h2>
            <br/>
            <div className="login">
                <label><b>User Name</b></label>
                <input type="text" name="Uname" onChange={handleUsernameInput} id="Uname" placeholder="Username"/>
                <br/><br/>
                <label><b>Password</b></label>
                <input type="Password" name="Pass" onChange={handlePasswordInput} id="Pass" placeholder="Password"/>
                <br/><br/>
                <input onClick={signInHandler} type="button" name="log" id="log" value="Log In Here"/>
            </div>
        </>
    )
}

export default SignIn;