import React from "react";

const SelectMultiOption = ({ prefix, options, value, changeHandler }) => {
    return (
        <ul className="ks-cboxtags">
            {
                options.map(option =>
                    <li key={`${prefix}-${option}`}>
                        <input type="checkbox" id={`${prefix}-${option}`} value={value} onChange={changeHandler} />
                        <label htmlFor={`${prefix}-${option}`}>{option}</label>
                    </li>
                )
            }

        </ul>
    )
}

export default SelectMultiOption