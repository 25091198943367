import {getCleaningOtherTask, getMovingOtherTask} from "../../../utils/constants";

const mapInfo = (value, plans, translate) => {
    const info = {
        plans: plans,
        address: {},
        destination: {},
        movingService: {},
        cleaningService: {},
        contact: {},
    };

    // address info
    info.address.zip = value.zip;
    info.address.location = value.location;
    info.address.street = value.street;
    info.address.no = value.no;
    info.address.buildingType = value.buildingType;
    info.address.lift = value.lift;
    info.address.floor = value.floor;
    info.address.room = value.room;
    info.address.area = value.area;

    // destination info
    info.destination.zip = value.to_zip;
    info.destination.location = value.to_location;
    info.destination.street = value.to_street;
    info.destination.no = value.to_no;
    info.destination.buildingType = value.to_buildingType;
    info.destination.lift = value.to_lift;
    info.destination.floor = value.to_floor;

    // moving info
    info.movingService.movingDate = value.movingDate;
    info.movingService.movingFlexibleDate = value.movingFlexibleDate;
    info.movingService.tasks = getMovingServiceTasks(value, translate);

    // cleaning info
    info.cleaningService.windows = value.cleaning_windows;
    info.cleaningService.shower = value.cleaning_shower;
    info.cleaningService.bath = value.cleaning_bath;
    info.cleaningService.wc = value.cleaning_wc;
    info.cleaningService.type_floor = value.cleaning_type_floor;
    info.cleaningService.window_sizes = value.cleaning_window_sizes;
    info.cleaningService.tasks = getCleaningServiceTasks(value, translate);

    // contact info
    info.contact.title = value.contact_title;
    info.contact.fullName = value.fullName;
    info.contact.phone = value.phone;
    info.contact.availability = value.contact_availability;
    info.contact.email = value.email;

    info.comment = value.comments;

    return info;
}

const getMovingServiceTasks = (value, translate) => {
    const tasks = getMovingOtherTask(translate);
    let result = [];

    tasks.filter(t => value[`moving-${t}`] === true).forEach(t => result.push(t));

    return result;
}

const getCleaningServiceTasks = (value, translate) => {
    const tasks = getCleaningOtherTask(translate);
    let result = [];

    tasks.filter(t => value[`cleaning-${t}`] === true).forEach(t => result.push(t));

    return result;
}

export default mapInfo;