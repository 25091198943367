
export const getFloors = translate => {
    return [
        translate('ground.floor.label'),
        translate('mezzanine.floor.label'),
        `1 ${translate('floor.label')}`,
        `2 ${translate('floors.label')}`,
        `3 ${translate('floors.label')}`,
        `4 ${translate('floors.label')}`,
        `5 ${translate('floors.label')}`,
        `6 ${translate('floors.label')}`,
        `7 ${translate('floors.label')}`,
        `8 ${translate('floors.label')}`,
        `9 ${translate('floors.label')}`,
        `10 ${translate('floors.label')}`,
        `>10 ${translate('floors.label')}`
    ]
}

export const getBuildingType = translate => {
    return [
     translate('apartment.label'),
     translate('house.label')
    ]
}

export const getLifts = translate => {
    return [
     translate('no.lift.label'),
     translate('with.lift.label')
    ]
}

export const getMovingOtherTask = translate => {
    return [
        translate('cellar.label'),
        translate('loft.label'),
        translate('garage.label'),
        translate('piano.label'),
        translate('grand.piano.label'),
        translate('storage.label'),
        translate('furniture.label')
    ]
}

export const getCleaningOtherTask = translate => {
    return [
        translate('cellar.label'),
        translate('loft.label'),
        translate('garage.label'),
        translate('balcony.label'),
        translate('carpet.cleaning.label'),
        translate('venetian.blinds.label'),
        translate('window.shutters.label'),
        translate('check.out.warranty.label')
    ]
}

export const getContactTitle = translate => {
    return [
        translate('mr.label'),
        translate('mrs.label'),
        translate('company.label'),
    ]
}

export const getAvailability = translate => {
    return [
        translate('morning.label'),
        translate('noon.label'),
        translate('afternoon.label'),
        translate('evening.label'),
        translate('Whole.day.label'),
        translate('office.hours.label'),
    ]
}

export const getMovingFlexibleDate = translate => {
    return [
        translate('no.flexible.label'),
        `+ / - 1 ${translate('day.label')}`,
        `+ / - 2 ${translate('day.label')}`,
        `+ / - 3 ${translate('day.label')}`
    ]
}

export const getRooms = translate => {
    return [
        `1.0 ${translate('room.count.label')}`,
        `1.5 ${translate('rooms.count.label')}`,
        `2.0 ${translate('rooms.count.label')}`,
        `2.5 ${translate('rooms.count.label')}`,
        `3.0 ${translate('rooms.count.label')}`,
        `3.5 ${translate('rooms.count.label')}`,
        `4.0 ${translate('rooms.count.label')}`,
        `4.5 ${translate('rooms.count.label')}`,
        `5.0 ${translate('rooms.count.label')}`,
        `5.5 ${translate('rooms.count.label')}`,
        `6.0 ${translate('rooms.count.label')}`,
        `6.5 ${translate('rooms.count.label')}`,
        `7.0 ${translate('rooms.count.label')}`,
        translate('more.than.7')
    ]
}

const windows = ["1", "2", "3", "4", "5-7", "8-10", "11-15", "16-20", "21-30", "31-50"]
export const getWindowsCount = translate => {
    return [
        ...windows,
        `${translate('more.than.label')} 50`
    ]

}

export const wc = ["1", "2", "3", "4", "5", "6"]
