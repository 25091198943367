import logo from '../assets/images/logo-Safari-Umzug.png'
import FooterExpanded from "./FooterExpanded";

const BlockFooter = () => {
    return (
        <footer className="block block--blue footer">
            <div className="container grid footer__sections">
                {/*<FooterExpanded items={['Website Hosting', 'Free Automated Wordpress', 'Migrations']} />*/}
                {/*<FooterExpanded items={['About', 'Affiliates', 'Blog']} />*/}
                {/*<FooterExpanded items={['Contact', 'Knowledge Base', 'FAQ']} />*/}
                {/*<FooterExpanded items={['Domain Checker', 'Domain Transfer', 'Free Domain']} />*/}
                <section className="footer__brand">
                    <img className='logo-size' src={logo} alt=""/>
                    <a className="footer__copyright" href="mailto:ms.safari@outlook.com">Copyright 2020 <br/>Mohammad Esmaeil Safari</a>
                </section>
            </div>
        </footer>
    )
}

export default BlockFooter