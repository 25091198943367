import React from "react";
import {useTranslation} from "react-i18next";
import InputText from "../../ui/InputText";
import Select from "../../ui/Select";
import {getAvailability, getContactTitle} from "../../../utils/constants";

const ContactInfo = ({formikProps}) => {
    const {t} = useTranslation()

    return (
        <div>
            <h3>{t('contact.info.label')}</h3>
            <div className="grid grid--stepper gap-2">
                <Select
                    id="contact_title"
                    label={t('contact.title.label')}
                    params={getContactTitle(t)}
                    value={formikProps.values.contact_title}
                    touched={formikProps.touched.contact_title}
                    error={formikProps.errors.contact_title}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <InputText
                    id="fullName"
                    label={t('full.name.label')}
                    placeholder={t('full.name.label')}
                    value={formikProps.values.fullName}
                    error={formikProps.errors.fullName}
                    touched={formikProps.touched.fullName}
                    blurHandler={formikProps.handleBlur}
                    type="text"
                    onChangeHandler={formikProps.handleChange}/>
                <InputText
                    id="phone"
                    label={t('phone.label')}
                    placeholder={t('phone.label')}
                    value={formikProps.values.phone}
                    error={formikProps.errors.phone}
                    touched={formikProps.touched.phone}
                    blurHandler={formikProps.handleBlur}
                    type="text"
                    onChangeHandler={formikProps.handleChange}/>
                <Select
                    id="contact_availability"
                    label={t('availability.label')}
                    params={getAvailability(t)}
                    value={formikProps.values.contact_availability}
                    touched={formikProps.touched.contact_availability}
                    error={formikProps.errors.contact_availability}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <InputText
                    id="email"
                    label={t('email.label')}
                    placeholder={t('email.label')}
                    value={formikProps.values.email}
                    error={formikProps.errors.email}
                    touched={formikProps.touched.email}
                    blurHandler={formikProps.handleBlur}
                    type="text"
                    onChangeHandler={formikProps.handleChange}/>
            </div>
        </div>
    )
}

export default ContactInfo;