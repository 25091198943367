import React from "react";
import InputText from "../../ui/InputText";
import Select from "../../ui/Select";
import {getBuildingType, getFloors, getLifts} from "../../../utils/constants";
import {useTranslation} from "react-i18next";

const Destination = ({formikProps}) => {
    const {t} = useTranslation()

    return (
        <div>
            <h3>{t('to.label')}</h3>
            <div className="grid grid--stepper gap-2">
                <InputText
                    id="to_zip"
                    label={t('zip.label')}
                    placeholder={t('zip.label')}
                    value={formikProps.values.to_zip}
                    error={formikProps.errors.to_zip}
                    touched={formikProps.touched.to_zip}
                    blurHandler={formikProps.handleBlur}
                    type="text"
                    onChangeHandler={formikProps.handleChange}
                />
                <InputText
                    id="to_location"
                    label={t('location.label')}
                    placeholder={t('location.label')}
                    value={formikProps.values.to_location}
                    error={formikProps.errors.to_location}
                    touched={formikProps.touched.to_location}
                    blurHandler={formikProps.handleBlur}
                    type="text"
                    onChangeHandler={formikProps.handleChange}
                />
                <InputText
                    id="to_street"
                    label={t('street.label')}
                    placeholder={t('street.label')}
                    value={formikProps.values.to_street}
                    error={formikProps.errors.to_street}
                    touched={formikProps.touched.to_street}
                    blurHandler={formikProps.handleBlur}
                    type="text"
                    onChangeHandler={formikProps.handleChange}
                />
                <InputText
                    id="to_no"
                    label={t('no.label')}
                    placeholder={t('no.label')}
                    value={formikProps.values.to_no}
                    error={formikProps.errors.to_no}
                    touched={formikProps.touched.to_no}
                    blurHandler={formikProps.handleBlur}
                    type="text"
                    onChangeHandler={formikProps.handleChange}
                />
                <Select
                    id="to_buildingType"
                    label={t('building.type.label')}
                    params={getBuildingType(t)}
                    value={formikProps.values.to_buildingType}
                    touched={formikProps.touched.to_buildingType}
                    error={formikProps.errors.to_buildingType}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <Select
                    id="to_lift"
                    label={t('lift.label')}
                    params={getLifts(t)}
                    value={formikProps.values.to_lift}
                    touched={formikProps.touched.to_lift}
                    error={formikProps.errors.to_lift}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
                <Select
                    id="to_floor"
                    label={t('floor.label')}
                    params={getFloors(t)}
                    value={formikProps.values.to_floor}
                    touched={formikProps.touched.to_floor}
                    error={formikProps.errors.to_floor}
                    blurHandler={formikProps.handleBlur}
                    onChangeHandler={formikProps.handleChange}/>
            </div>
        </div>
    )
}

export default Destination;