import React, {useState} from "react";
import AddressInfo from "./component/AddressInfo";
import {Formik} from "formik";
import getFormInitialValue from "./service/formInitialValue";
import getFormValidation from "./service/formValidation";
import Destination from "./component/DestinationInfo";
import CleaningInfo from "./component/CleaningInfo";
import ContactInfo from "./component/ContactInfo";
import mapInfo from "./service/mapFormikValue";
import {useTranslation} from "react-i18next";
import StepperBtn from "./StepperBtn";
import {CLEANING, DISPOSAL, MOVING} from "./BlockPlan";
import {useHistory} from "react-router-dom";
import {persist} from "../../service/task-store"

const Master = ({stepperNavigationHandler, plans}) => {
    const {t} = useTranslation();
    const history = useHistory();
    const [accepted, setAccepted] = useState(false);
    const [ruleDivClass, setRuleDivClass] = useState("hide_div")

    const planInfo = {
        isMoving: plans.findIndex(b => b === MOVING) > -1,
        isCleaning: plans.findIndex(b => b === CLEANING) > -1,
        isDisposal: plans.findIndex(b => b === DISPOSAL) > -1,
    };


    const handleAccepted = e => {
        setAccepted(e.target.checked)

        if (e.target.checked) {
            setRuleDivClass("hide_div")
        } else {
            setRuleDivClass("input__label-required")
        }
    }

    const handleBack = () => {
        stepperNavigationHandler(0)
    }

    const submitHandler = (values, actions) => {
        actions.setSubmitting(false);

        if (!accepted) {
            setRuleDivClass("input__label-required")
            return
        }

        const requestBody = mapInfo(values, plans, t);

        console.log("Start Sending . . .", requestBody)
        persist(requestBody).then(_ => {
            history.push("/thanks")
        });
    }

    return (
        <Formik
            initialValues={getFormInitialValue()}
            validationSchema={getFormValidation(planInfo, t)}
            onSubmit={submitHandler}
        >
            {props => (
                <>
                    <div className="grid grid--1x2 gap-2">
                        <AddressInfo formikProps={props} isMoving={planInfo.isMoving}/>
                        {planInfo.isMoving && <Destination formikProps={props}/>}
                        {planInfo.isCleaning && <CleaningInfo formikProps={props}/>}
                        <ContactInfo formikProps={props}/>
                        <div>
                            <h3>{t('comments.label')}</h3>
                            <textarea
                                id="comments"
                                name="comments"
                                className="text__area"
                                placeholder={t('comments.label')}
                                value={props.values.comments}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                            />
                        </div>
                    </div>
                    <input id="checkRule" onChange={e => handleAccepted(e)} value={accepted} type='checkbox'
                           name="checkRule"/>
                    <label htmlFor="checkRule">{` ${t('policies.label')} `}<a
                        href="https://www.google.com/">{t('here.label')}</a>
                    </label>
                    <div className={ruleDivClass}>{t('policies.validation')}</div>
                    <StepperBtn step={1} handlerNext={props.handleSubmit} handleBack={handleBack}/>
                </>
            )}
        </Formik>
    )
}

export default Master;